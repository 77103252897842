import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageModal } from '@services/message-modal/message-modal';

@Component({
  selector: 'app-mobile-confirm-message-modal',
  templateUrl: './mobile-confirm-message-modal.component.html',
  styleUrls: ['./mobile-confirm-message-modal.component.scss'],
})
export class MobileConfirmMessageModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public messageData: MessageModal,
    public dialogRef: MatDialogRef<MobileConfirmMessageModalComponent>
  ) {}

  /**
   * Closes the modal
   */
  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
