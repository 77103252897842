import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { InterceptorService } from './interceptor.service';
import { AuthReloadedInterceptor } from '@app-core/http/interceptors/auth-reloaded.interceptor';
import { AuthReloadedRegisterInterceptor } from './auth-reloaded-register.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthReloadedInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthReloadedRegisterInterceptor, multi: true },
];
