import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app-core/authentication/authentication.service';

@Injectable()
export class AuthReloadedInterceptor implements HttpInterceptor {
  token: '';
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = this.authenticationService.getCookie('user');
    this.token = user.access_token;
    if (request.url.indexOf(environment.API_DOMAIN_RELOADED) === 0 && this.token) {
      const authToken = this.token;
      const clonedReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`),
      });
      return next.handle(clonedReq);
    }
    return next.handle(request);
  }
}
