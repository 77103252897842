import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { isCandidate, saveUserData } from '@app-core/store/actions/applicant-form/applicant-form.actions';
import { Store } from '@ngrx/store';
import _get from 'lodash/get';
import { MessageModalService } from '@services/message-modal/message-modal.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import * as AWS from 'aws-sdk';

enum CountryAbbreviation {
  Colombia = 'col',
  Guatemala = 'gtm',
  Mexico = 'mex',
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Lean Staffing';
  userData = {
    personId: '',
    verificationCode: '',
  };
  snackBarUser = null;
  private ngUnsubscribe$: Subject<void> = new Subject();
  public colombiaAbbreviation = CountryAbbreviation.Colombia;
  public guatemalaAbbreviation = CountryAbbreviation.Guatemala;
  public mexicoAbbreviation = CountryAbbreviation.Mexico;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store,
    private mssageModalService: MessageModalService,
    public translate: TranslateService
  ) {
    // Set access key for GeoPosition AWS
    AWS.config.update({
      credentials: {
        accessKeyId: environment.AWS_GEO_ACCESS_KEY,
        secretAccessKey: environment.AWS_GEO_SECRET_ACCESS_KEY,
      },
      region: environment.AWS_REGION,
    });

    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    translate.use('en');

    this.userData = this.authenticationService.getCookie('user');
    if (this.userData) {
      this.store.dispatch(
        saveUserData({ payload: { personId: this.userData?.personId, verificationCode: this.userData.verificationCode } })
      );
    }
    this.detectChangesRoute();
  }

  ngOnInit() {
    // this.initLanguage();
  }

  /**
   * This method detect changes routes and validate person ID
   * if have person ID inmediatly validate if no is in applicant form to show the snack bar
   * else update cookie user url to we know in which url was the user.
   * We save the reference the snackbar to when is other view close it and show if is permit url
   */
  detectChangesRoute() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        const userData = this.authenticationService.getCookie('user');
        const url = _get(routerEvent, 'url', '');
        const personId = _get(userData, 'personId', 0);

        if (personId) {
          this.handleUserSnackBar();

          if (this.notIsInApplicantForm(url)) {
            this.showUnfinishedProcessModal();
          } else {
            this.updateUserCookie(url);
          }
        }
      }
    });
  }

  handleUserSnackBar() {
    if (this.snackBarUser) {
      this.snackBarUser.dismiss();
      this.snackBarUser = null;
    }
  }

  showUnfinishedProcessModal() {
    const ref = this.mssageModalService.showConfirmMessage({
      title: 'Unfinished process',
      message: 'You have an unfinished process, do you want to continue it?',
      cancelButtonName: 'Cancel',
      buttonName: 'Yes, Continue',
      okButtonName: 'Yes, Continue',
      backgroundColor: '#FFECB3',
      color: '#FF8F00',
      icon: 'warning',
    });

    ref
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((success) => {
        if (success) {
          this.continueUnfinishedProcess();
        }
      });
  }

  continueUnfinishedProcess() {
    this.store.dispatch(isCandidate({ payload: { type: 'continueProcess', label: 'ID number', placeholder: 'Type your code' } }));
    this.router.navigateByUrl('/register/validationNumber');
  }

  updateUserCookie(url: string) {
    if (!this.haveRegisterOr404InUrl(url)) {
      this.authenticationService.updateCookie('user', { url });
    }
  }

  /**
   * User URL
   * @param url - this is string with url
   * @returns - it validate url navigation and return boolean
   */

  notIsInApplicantForm(url): boolean {
    if (this.validateUrl(url)) {
      return true;
    }
    return false;
  }

  haveRegisterOr404InUrl(url: string) {
    return url.includes('/register') || url === '/404';
  }

  validateUrl(url: string) {
    return url === '/login' || url === '/register';
  }

  /**
   * function allows to obtain the geolocation and implement georeverse with aws using the lat and the long,
   * the country can be obtained and in this way the Spanish language
   * will be set for Colombia, Mexico and Guatemala when starting the application
   * @memberof AppComponent
   */

  // async initLanguage() {
  //   try {
  //     if ('geolocation' in navigator) {
  //       const position = await this.getCurrentPosition();
  //       const lat = position.coords.latitude;
  //       const long = position.coords.longitude;

  //       const client = new LocationClient({
  //         region: environment.AWS_REGION,
  //         credentials: {
  //           accessKeyId: environment.AWS_GEO_ACCESS_KEY,
  //           secretAccessKey: environment.AWS_GEO_SECRET_ACCESS_KEY,
  //         },
  //       });
  //       const params = {
  //         IndexName: environment.AWS_INDEX_NAME,
  //         Position: [long, lat],
  //         MaxResults: 1,
  //       };
  //       const command = new SearchPlaceIndexForPositionCommand(params);
  //       const response = await client.send(command);
  //       const country = response?.Results[0]?.Place.Country.toLowerCase();
  //       localStorage.setItem('country', country);
  //       if (
  //         country == CountryAbbreviation.Colombia ||
  //         country === CountryAbbreviation.Guatemala ||
  //         country === CountryAbbreviation.Mexico
  //       ) {
  //         this.translate.use('es');
  //         this.translate.setDefaultLang('es');
  //         localStorage.setItem('language', 'es');
  //       } else {
  //         this.translate.use('en');
  //         this.translate.setDefaultLang('en');
  //         localStorage.setItem('language', 'en');
  //       }
  //     }
  //   } catch (error) {
  //     this.snackBarService.openFailure('Location has not been accepted', null);
  //   }
  // }

  /**
   * This function allows you to obtain the lat and long from where you are connected using the browser API
   * @return {*}  {Promise<GeolocationPosition>}
   * @memberof AppComponent
   */

  getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
}
