import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HttpService } from '@app-core/http/http.service';
import { DataService } from '@services/data/data.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import _get from 'lodash/get';
// import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  PATH_URL = `${environment.API_DOMAIN_RELOADED_REGISTER}`;

  constructor(
    public httpService: HttpService,
    public httpClient: HttpClient,
    public dataService: DataService,
    public cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Gets ths session authorization token
   */
  public getToken(): string {
    return this.getTokenCokie()?.token;
  }

  /**
   * Save token in cokies
   * @param data token information
   */
  public setTokenCokie(data): void {
    const results: any = {
      user: {
        id: data.id,
        code: data.code,
        name: data.name,
        first_name: data.first_name,
        last_name: data.last_name,
        Permissions: data.Permissions,
        avatar: data.avatar,
        person: data.person,
        profile: data.profile,
        email: data.email,
        template: data.template,
        position: data.position,
        customerProfile: data.customer_profile_id,
        main_permission: data.main_permission,
        branch: data.branch,
        defaultUrl: data.defaultUrl,
      },
      token: data.token,
      refreshToken: data.refreshToken,
    };
    if (data.customerr) {
      results.customer = data.customerr;
    }
    const baseDomain = environment.URL_DOMAIN;
    const expireAfter = new Date();
    expireAfter.setDate(expireAfter.getDate() + 2);
    // setup cookie
    this.cookieService.set('data', JSON.stringify(results), expireAfter, '/', baseDomain);
  }

  public setCookie(key, data) {
    const baseDomain = environment.URL_DOMAIN;
    const expireAfter = new Date();
    expireAfter.setDate(expireAfter.getDate() + 2);
    this.cookieService.set(key, JSON.stringify(data), expireAfter, '/', baseDomain);
  }

  public updateCookie(key, dataUpdate) {
    const data: string = this.cookieService.get(key) || '{}';
    let cookieData = JSON.parse(data);
    cookieData = { ...cookieData, ...dataUpdate };
    this.setCookie(key, cookieData);
  }

  public getCookie(key) {
    const data: string = this.cookieService.get(key) || '{}';
    return JSON.parse(data);
  }

  /**
   * Starts a new user session with the provided credentials
   */
  public authenticate(username: string, password: string): Observable<any> {
    const path = `${this.PATH_URL}/api/auth/login/`;
    const body = { email: username, password };
    return this.httpClient.post(path, body).pipe(
      catchError((error: any) => {
        // Swal.fire('Unable to login with the provided credentials', 'Please verify your credentials', 'error');
        return throwError(error);
      })
    );
  }

  public authenticateCustomer(customer) {
    const path = `customer/customer/${customer}/`;
    return this.httpService.get<any>(path, {});
  }

  /**
   * Ends the current user's session
   */
  public logout(): void {
    this.dataService.clearUserData();
    localStorage.removeItem('TRY-id');
    const baseDomain = environment.URL_DOMAIN;
    this.cookieService.deleteAll('/', baseDomain);
    this.goToLogin();
  }

  removeCookie(name: string): void {
    this.cookieService.delete(name);
  }

  /**
   * Check if there is an user stored locally
   */
  public isLoggedIn(): boolean {
    return this.cookieService.get('data') ? true : false;
  }

  /**
   * Go to Login Page
   */

  goToLogin(): void {
    this.document.location.href = environment.URL_LOGIN;
  }

  public setToken(token): void {
    localStorage.setItem('token', token);
  }

  public getTokenCokie() {
    if (this.cookieService.get('data')) {
      return JSON.parse(this.cookieService.get('data'));
    }
  }

  /**
   * Check if user is Candidate
   * Special case for redirect
   */
  public isCandidate(): boolean {
    if (localStorage.getItem('permissions')) {
      const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
      return storedPermissions.includes('Candidate');
    }
  }

  validateUser(email): Observable<any> {
    const path = `${this.PATH_URL}/api/v1/authentication/email-check/`;
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(path, { params });
  }

  validateCandidate(email: string): Observable<any> {
    const path = `${this.PATH_URL}/api/v1/authentication/email-check-candidate/`;
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(path, { params });
  }

  updateAvatar(avatar) {
    const user = JSON.parse(this.cookieService.get('data'));
    user.avatar = avatar;
    const baseDomain = environment.URL_DOMAIN;
    const expireAfter = new Date();
    expireAfter.setDate(expireAfter.getDate() + 1);
    // setup cookie
    this.cookieService.set('data', JSON.stringify(user), expireAfter, '/', baseDomain);
  }

  public microsoftAuth(body) {
    const path = `${this.PATH_URL}/api/auth/microsoft/`;
    return this.httpClient.post<any>(path, body);
  }

  public refreshToken(refreshToken: string) {
    return this.httpClient.post(`${environment.API_DOMAIN_RELOADED}/api/auth/token/refresh/`, { refresh: refreshToken });
  }
}
