import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Benefit } from '@app-main/applicant-portal/components/steps/benefits/benefits.component';
import {
  BenefitsResponse,
  ContactRelationshipsResponse,
  CurrenciesResponse,
  Education,
  EducationLevelsResponse,
  Gender,
  ProfessionalCareer,
  Prospect,
  WorkExperience,
} from '@models/applicant-reloaded.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralReloadedService {
  viewTitle = 'Applicant Portal';
  viewSidebar = true;
  BASE_PATH = `${environment.API_DOMAIN_RELOADED}/api/v1/general`;
  PATH = `${environment.API_DOMAIN_RELOADED}/api/v1`;
  BASE_PATH_PROSPECT = `${environment.API_DOMAIN_RELOADED}/api/v1/prospect`;

  constructor(private http: HttpClient) {}

  /**
   * Sets the title for the platform views.
   *
   * @param title The string to put as title.
   */
  setViewTitle(title: string): void {
    this.viewTitle = title;
  }

  /**
   * Sets the status for the sidebar.
   *
   * @param title The string to put as title.
   */
  setViewSidebar(status: boolean): void {
    this.viewSidebar = status;
  }

  /**
   * Gets the Carriers list.
   */
  getCareers(): Observable<ProfessionalCareer[]> {
    const path = `${this.BASE_PATH}/professional-careers/`;
    return this.http.get<ProfessionalCareer[]>(path);
  }

  /**
   * Gets Education Levels List.
   */
  getEducationLevels(): Observable<EducationLevelsResponse> {
    const path = `${this.BASE_PATH}/education-levels/`;
    return this.http.get<EducationLevelsResponse>(path);
  }

  /**
   * Gets Genders List.
   */
  getGenders(): Observable<Gender[]> {
    const genders: Gender[] = [
      { id: 1, name: 'male' },
      { id: 2, name: 'female' },
      { id: 3, name: 'other' },
    ];

    return of(genders);
  }

  /**
   * Gets the Contact Relationships list.
   */
  getContactRelationships(): Observable<ContactRelationshipsResponse> {
    const path = `${this.BASE_PATH}/family-relationships/`;
    return this.http.get<ContactRelationshipsResponse>(path);
  }

  /**
   * Saves the Person info.
   */
  saveProfileInfo(id: string, data: FormData): Observable<any> {
    const path = `${this.BASE_PATH_PROSPECT}/prospect/prospect-personal-information/${id}/`;
    return this.http.put(path, data);
  }

  /**
   * Gets the Currencies list.
   */
  getCurrencies(): Observable<CurrenciesResponse> {
    const path = `${this.BASE_PATH}/currency/`;
    return this.http.get<CurrenciesResponse>(path);
  }

  /**
   * Get Applicant information.
   */
  getApplicantInformation(userUUID: string): Observable<Prospect> {
    const path = `${this.BASE_PATH_PROSPECT}/applicants/${userUUID}/`;
    return this.http.get<Prospect>(path);
  }

  /**
   * Get Applicant information.
   */
  getProfileInformation(userUUID: string): Observable<any> {
    const path = `${this.PATH}/recruitment/applicant-requisition-profiles/${userUUID}/`;
    return this.http.get(path);
  }

  getTalentAdquisition(userUUID: string) {
    const path = `${this.PATH}/recruitment/talents/${userUUID}/`;
    return this.http.get(path);
  }

  /**
   * Updates the Applicant information.
   */
  updateApplicantInformation(userUUID: string, payload): Observable<any> {
    const path = `${this.BASE_PATH_PROSPECT}/prospect-personal-information/${userUUID}/`;
    return this.http.patch(path, payload);
  }

  /**
   * Get Applicant Education list.
   */
  getEducationList(userUUID: string): Observable<Education[]> {
    const path = `${this.BASE_PATH_PROSPECT}/educations/?applicant=${userUUID}`;
    return this.http.get<Education[]>(path);
  }

  /**
   * Adds Applicant Education Item.
   */
  addEducation(payload): Observable<Education[]> {
    const path = `${this.BASE_PATH_PROSPECT}/educations/`;
    return this.http.post<Education[]>(path, payload);
  }

  /**
   * Updates Applicant Education Item.
   */
  updateEducation(uuid: string, payload): Observable<Education[]> {
    const path = `${this.BASE_PATH_PROSPECT}/educations/${uuid}/`;
    return this.http.patch<Education[]>(path, payload);
  }

  /**
   * Deletes Applicant Education Item.
   */
  deleteEducation(uuid: string): Observable<Education[]> {
    const path = `${this.BASE_PATH_PROSPECT}/educations/${uuid}/`;
    return this.http.delete<Education[]>(path);
  }

  /**
   * Gets Professional Careers list.
   */
  getProfessionalCareersList(): Observable<ProfessionalCareer[]> {
    const path = `${this.BASE_PATH}/professional-careers/`;
    return this.http.get<ProfessionalCareer[]>(path);
  }

  /**
   * Get Applicant Experience list.
   */
  getWorkExperienceList(userUUID: string): Observable<WorkExperience[]> {
    const path = `${this.BASE_PATH_PROSPECT}/experiences/?applicant=${userUUID}`;
    return this.http.get<WorkExperience[]>(path);
  }

  /**
   * Adds Applicant Experience Item.
   */
  addExperience(payload): Observable<WorkExperience[]> {
    const path = `${this.BASE_PATH_PROSPECT}/experiences/`;
    return this.http.post<WorkExperience[]>(path, payload);
  }

  /**
   * Updates Applicant Experience Item.
   */
  updateExperience(uuid: string, payload): Observable<WorkExperience[]> {
    const path = `${this.BASE_PATH_PROSPECT}/experiences/${uuid}/`;
    return this.http.patch<WorkExperience[]>(path, payload);
  }

  /**
   * Deletes Applicant Experience Item.
   */
  deleteExperience(uuid: string): Observable<WorkExperience[]> {
    const path = `${this.BASE_PATH_PROSPECT}/experiences/${uuid}/`;
    return this.http.delete<WorkExperience[]>(path);
  }

  getBenefits(params, language): Observable<Benefit[]> {
    const path = `${this.BASE_PATH}/benefits/`;
    const headers = new HttpHeaders({
      'Accept-Language': language,
    });

    return this.http.get<BenefitsResponse>(path, { params, headers }).pipe(
      map((data: BenefitsResponse) => {
        return data.results.map((benefit) => {
          return {
            title: benefit?.name,
            altTitle: benefit?.subtitle,
            subtitle: benefit?.subtitle,
            icon: benefit?.thumbnail,
            width: 57,
            content: benefit?.content,
            type: params.category,
            isLarge: this.isLarge(benefit?.name),
          } as Benefit;
        });
      })
    );
  }

  getKeys(): Observable<Benefit[]> {
    const path = `${this.BASE_PATH}/benefits/?order_by=order&category=BENEFICIOS GENERALES&is_active=true/`;
    return this.http.get<BenefitsResponse>(path).pipe(
      map((data: BenefitsResponse) => {
        return data.results.map((benefit) => {
          return {
            title: benefit?.name,
            altTitle: benefit?.subtitle,
            subtitle: benefit?.subtitle,
            icon: benefit?.thumbnail,
            width: 57,
            content: benefit?.content,
          } as Benefit;
        });
      })
    );
  }

  isLarge(benefit: string) {
    if (benefit.includes('Educativos') || benefit.includes('Hoteles')) {
      return true;
    }
    return false;
  }
}
