import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = 'lean-staffinghelp.zendesk.com/';
  callback(zE) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
  }
}
