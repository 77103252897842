import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '@app-core/http/http.service';
import { map } from 'rxjs/operators';
import _get from 'lodash/get';
import { environment } from '@environments/environment';
import { AvailabilityLevel, EnglishLevel, Gender, MaritalStatus, PictureResponse } from '@models/applicant-reloaded.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpService: HttpService, private httpClient: HttpClient) {}

  API_URL_PUBLIC = `${environment.API_DOMAIN_RELOADED}/public-rest/v1`;

  public getDocumentTypes(): Observable<any> {
    const path = 'general/identitydocumenttype/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getIdentityDocumentType(id: any): Observable<any> {
    const path = `general/identitydocumenttype/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public SaveUploadCandidate(id, body) {
    const path = `recruitment/candidate/${id}/end_supervision/`;
    return this.httpService.post<any>(path, body);
  }

  public deleteMedia(id): Observable<any> {
    const path = `recruitment/candidate/${id}/end_supervision/`;
    return this.httpService.delete<any>(path);
  }

  public getGenders(): Observable<Gender[]> {
    const path = 'general/gender/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpService.get<Gender[]>(path, { params, headers });
  }

  public getGender(id: string): Observable<Gender[]> {
    const path = `general/gender/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<Gender[]>(path, { params, headers });
  }

  public getMaritalStatus(id: any): Observable<MaritalStatus> {
    const path = `general/maritalstatus/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpService.get<MaritalStatus>(path, { params, headers });
  }

  public getCity(id: any): Observable<any> {
    const path = `general/city/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getCitiesWork(): Observable<any> {
    const path = 'general/city/?requisition=True';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getEnglishLevel(id: any): Observable<EnglishLevel> {
    const path = `general/englishlevel/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpService.get<EnglishLevel>(path, { params, headers });
  }

  public getAvailabilityLevels(): Observable<AvailabilityLevel[]> {
    const path = 'general/availability/';
    const params = new HttpParams().set('active', 'True');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpService.get<AvailabilityLevel[]>(path, { params, headers });
  }

  public getRequisitionCities(): Observable<any> {
    const path = 'general/city/';
    const params = new HttpParams().set('requisition', 'True');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getVisaType(id: any): Observable<any> {
    const path = `general/visatype/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getTemporalCompanies(): Observable<any> {
    const path = 'general/temporalcompany/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getCities(params): Observable<any> {
    const path = 'general/city/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getStates(params): Observable<any> {
    const path = 'general/state/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getCountries(): Observable<any> {
    const path = 'general/country/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getStatus(): Observable<any> {
    const path = 'general/Status/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getBranch(id): Observable<any> {
    const path = 'general/city/';
    const params = new HttpParams().set('profile', id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getAreas() {
    const path = 'general/area/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getValidateEmail(email: string) {
    const path = `${this.API_URL_PUBLIC}/prospect/prospects/validate-prospect-email/`;
    const params = new HttpParams().set('email', email);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get(path, { params, headers }).pipe(map((result) => _get(result, 'results', [])[0]));
  }

  public getValidateIdentityDocument(identityDocument: string) {
    const path = 'person/personvalid/';
    const params = new HttpParams().set('document', identityDocument);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers }).pipe(map((result) => _get(result, 'results', [])[0]));
  }

  public getEducationLevels() {
    const path = `general/educationlevel/`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'content-type': 'multipart/form-data',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getUserInformation(id) {
    const path = `user/user/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { headers });
  }

  public getFeedbackAnswers(): Observable<any> {
    const path = 'general/feedback_answer/list/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getFeedbackAnswer(id): Observable<any> {
    const path = `general/feedback_answer/detail/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getFeedbackQuestions(): Observable<any> {
    const path = 'general/feedback_question/list/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getFeedbackQuestion(id): Observable<any> {
    const path = `general/feedback_question/detail/${id}`;
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public sendResetPassword(params) {
    const path = `${environment.API_DOMAIN_RELOADED}/api/auth/password/reset/`;
    return this.httpClient.post<any>(path, params);
  }

  public sendSetPassword(params, language) {
    const path = `${environment.API_DOMAIN_RELOADED}/api/auth/password/reset/confirm/`;
    const headers = this.getLanguageHeaders(language);
    return this.httpClient.post<any>(path, params, { headers });
  }

  public sendNewPassword(params, language) {
    const path = `user/activate/`;
    const headers = this.getLanguageHeaders(language);
    return this.httpService.post<any>(path, params, { headers });
  }

  getLanguageHeaders(language): HttpHeaders {
    const headers = new HttpHeaders({
      'Accept-Language': language,
      'Content-Type': 'application/json',
    });
    return headers;
  }

  public getMedias(): Observable<any> {
    const path = 'recruitment/new/aplicant/informedby/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, { params, headers });
  }

  public getPictures(max = '5'): Observable<PictureResponse> {
    const path = 'recruitment/new/aplicant/random_pic/';
    const params = new HttpParams().set('max', max);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpService.get<PictureResponse>(path, { params, headers });
  }

  getSkills(verificateCode?: any): Observable<any> {
    const path = 'recruitment/new/aplicant/skills/';
    let params = {};
    if (verificateCode) {
      params = new HttpParams().set('code', verificateCode);
    }

    return this.httpService.get<any>(path, { params });
  }

  getEnglishLevels(): Observable<any> {
    const path = 'recruitment/new/aplicant/english_level/';
    return this.httpService.get<any>(path);
  }

  getVisaTypes(): Observable<any> {
    const path = 'recruitment/new/aplicant/visa_type/';
    return this.httpService.get<any>(path);
  }

  getLanguages(): Observable<any> {
    const path = 'recruitment/new/aplicant/language/';
    return this.httpService.get<any>(path);
  }

  getHobbies(verificateCode?: any): Observable<any> {
    const path = 'recruitment/new/aplicant/interestAndHobby/';
    let params = {};
    if (verificateCode) {
      params = new HttpParams().set('code', verificateCode);
    }
    return this.httpService.get<any>(path, { params });
  }

  public getMaritalStatuses(): Observable<MaritalStatus> {
    const path = 'recruitment/new/aplicant/marital_status/';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<MaritalStatus>(path, { params, headers });
  }
}
