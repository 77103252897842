import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, take, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { AuthenticationService } from '@app-core/authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isRedirection = JSON.parse(localStorage.getItem('redirection'));
    if (
      request.url.indexOf(environment.API_DOMAIN_RELOADED) === 0 &&
      !request.url.includes('/token/refresh') &&
      !request.url.includes('/login')
    ) {
      const authToken = this.authService.getToken();
      if (authToken) {
        request = this.addToken(request, authToken);
      }
      return next.handle(request).pipe(
        catchError((err) => {
          if (err && err.status === 401 && !isRedirection) {
            return this.refreshToken(next, request);
          }
          return throwError(() => err);
        })
      );
    }
    return next.handle(request);
  }

  refreshToken(next: HttpHandler, request: HttpRequest<any>) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getTokenCokie()?.refreshToken;
      return this.authService.refreshToken(refreshToken).pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          this.authService.setToken(res.access);
          const data = this.authService.getTokenCokie();
          data.token = res.access;
          this.authService.setTokenCokie(data);
          this.refreshTokenSubject.next(res.access);
          return next.handle(this.addToken(request, res.access));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(() => err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => !!token),
      take(1),
      switchMap((token) => next.handle(this.addToken(request, token)))
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
