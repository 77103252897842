import { Action, createReducer, on } from '@ngrx/store';
import { ApplicantState } from '@app-core/store/appState.model';
import {
  updatePersonalInformation,
  updateMyProfile,
  updateEducationalInformation,
  updateWorkExperience,
  updatePersonalInformationPercentage,
  updateMyProfilePercentage,
  updateEducationalInformationPercentage,
  updateWorkExperiencePercentage,
  updateProfilePicture,
  updateStepPosition,
  saveUserData,
  changeGenderPictureProfile,
  changeFullNamePictureProfile,
  updateValidationMyPersonalInformation,
  updateValidationMyProfile,
  updateValidationEducationInformation,
  updateValidationWorkExperience,
  updateAudioIsUploaded,
  updateTotalPercentage,
  updateSkills,
  updateEnglishLevel,
  updateLanguages,
  updateInterests,
  updateVisaTypes,
  updateGenders,
  updateDocumentTypes,
  updateMaritalStatuses,
  updateProgressInformation,
  updateContactRelationships,
  isEditing,
  isEditFromCurriculum,
  isCandidate,
} from '@app-core/store/actions/applicant-form/applicant-form.actions';
import _get from 'lodash/get';

export const initialState: ApplicantState = {
  personalInformation: null,
  personalResidenceInformation: null,
  myProfile: null,
  educationalInformation: [],
  workExperiences: null,
  totalPercentage: 0,
  personalInformationPercentage: 0,
  myProfilePercentage: 0,
  educationalInformationPercentage: 0,
  workExperiencePercentage: 0,
  profilePicture: null,
  stepPosition: {
    step: 0,
    title: 'Personal Information',
    label: 'personalInformation',
    state: 'Unfinished',
  },
  userData: null,
  genderPictureProfile: 0,
  fullNamePictureProfile: '',
  validationsApplicantForm: {
    isPersonalInformationValid: false,
    isMyProfileValid: false,
    isEducationInformationValid: false,
    isWorkExperienceValid: false,
  },
  audioIsUploaded: false,
  documentTypes: [],
  genders: [],
  maritalStatuses: [],
  contactRelationships: [],
  skills: [],
  englishLevels: [],
  languages: [],
  interests: [],
  visaTypes: [],
  updateProgressInformation: null,
  isEditing: false,
  isEditFromCurriculum: false,
  isCandidate: {
    type: 'continueProcess',
    title: "Continue your process, it's simple!",
    subtitle: 'Enter your ID and next the process',
    label: 'ID number',
    idInput: 'continueProcess',
    placeholder: 'Type your ID number',
  },
};

const reducer = createReducer(
  initialState,
  on(updatePersonalInformation, (state, action) => {
    const personalInformation = _get(state, 'personalInformation', {});
    const payload = _get(action, 'payload', {});
    return { ...state, personalInformation: { ...personalInformation, ...payload } };
  }),
  on(updateMyProfile, (state, action) => {
    const myProfile = _get(state, 'myProfile', {});
    const payload = _get(action, 'payload', {});
    return { ...state, myProfile: { ...myProfile, ...payload } };
  }),
  on(updateEducationalInformation, (state, action) => ({ ...state, educationalInformation: action.payload })),
  on(updateWorkExperience, (state, action) => {
    const payload = _get(action, 'payload', {});
    return { ...state, workExperiences: payload };
  }),
  on(updatePersonalInformationPercentage, (state, action) => ({ ...state, personalInformationPercentage: action.percentage })),
  on(updateMyProfilePercentage, (state, action) => ({ ...state, myProfilePercentage: action.percentage })),
  on(updateEducationalInformationPercentage, (state, action) => ({ ...state, educationalInformationPercentage: action.percentage })),
  on(updateWorkExperiencePercentage, (state, action) => ({ ...state, workExperiencePercentage: action.percentage })),
  on(updateTotalPercentage, (state, action) => ({ ...state, totalPercentage: action.percentage })),
  on(updateProfilePicture, (state, action) => ({ ...state, profilePicture: action.profilePicture })),
  on(updateStepPosition, (state, action) => ({ ...state, stepPosition: action.payload })),
  on(saveUserData, (state, action) => ({ ...state, userData: action.payload })),
  on(changeGenderPictureProfile, (state, action) => ({ ...state, genderPictureProfile: action.payload })),
  on(changeFullNamePictureProfile, (state, action) => ({ ...state, fullNamePictureProfile: action.payload })),
  on(updateValidationMyPersonalInformation, (state, action) => ({
    ...state,
    validationsApplicantForm: { ...state.validationsApplicantForm, isPersonalInformationValid: action.payload },
  })),
  on(updateValidationMyProfile, (state, action) => ({
    ...state,
    validationsApplicantForm: {
      ...state.validationsApplicantForm,
      isMyProfileValid: action.payload,
    },
  })),
  on(updateValidationEducationInformation, (state, action) => ({
    ...state,
    validationsApplicantForm: { ...state.validationsApplicantForm, isEducationInformationValid: action.payload },
  })),
  on(updateValidationWorkExperience, (state, action) => ({
    ...state,
    validationsApplicantForm: { ...state.validationsApplicantForm, isWorkExperienceValid: action.payload },
  })),
  on(updateAudioIsUploaded, (state, action) => ({ ...state, audioIsUploaded: action.payload })),
  on(updateDocumentTypes, (state, action) => ({ ...state, documentTypes: action.payload })),
  on(updateGenders, (state, action) => ({ ...state, genders: action.payload })),
  on(updateMaritalStatuses, (state, action) => ({ ...state, maritalStatuses: action.payload })),
  on(updateSkills, (state, action) => ({ ...state, skills: action.payload })),
  on(updateEnglishLevel, (state, action) => ({ ...state, englishLevels: action.payload })),
  on(updateLanguages, (state, action) => ({ ...state, languages: action.payload })),
  on(updateInterests, (state, action) => ({ ...state, interests: action.payload })),
  on(updateContactRelationships, (state, action) => ({ ...state, contactRelationships: action.payload })),
  on(updateVisaTypes, (state, action) => ({ ...state, visaTypes: action.payload })),
  on(updateProgressInformation, (state, action) => ({ ...state, progressInformation: action.payload })),
  on(isEditing, (state, action) => ({ ...state, isEditing: action.payload })),
  on(isEditFromCurriculum, (state, action) => ({ ...state, isEditFromCurriculum: action.payload })),
  on(isCandidate, (state, action) => ({ ...state, isCandidate: action.payload }))
);

export function applicantReducer(state: ApplicantState | undefined, action: Action) {
  return reducer(state, action);
}
