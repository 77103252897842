import { ActionReducerMap } from '@ngrx/store';
import { applicantReducer } from '@app-core/store/reducers/applicant-form/applicant-form.reducer';
import { applicantPortalReducer } from '@app-core/store/reducers/applicant-portal/applicant-portal.reducer';

export interface AppReducer {
  applicantForm: any;
  applicantPortal: any;
}

export const appStoreReducers: ActionReducerMap<AppReducer> = {
  applicantForm: applicantReducer,
  applicantPortal: applicantPortalReducer,
};
