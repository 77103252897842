import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { DataService } from '@services/data/data.service';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { SnackBarService } from '@services/snackBar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    public http: HttpClient,
    public dataService: DataService,
    public cookieService: CookieService,
    public snackBarService: SnackBarService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  public get<T>(path: string, options?: any): any {
    return this.http.get<T>(`${path}`, options).pipe(catchError(this.handleError.bind(this)));
  }

  public post<T>(path: string, body: any, options?: any): any {
    return this.http.post<T>(`${path}`, body, options).pipe(catchError(this.handleError.bind(this)));
  }

  public postHeader<T>(path: string, body: any, headers: any) {
    return this.http.post<T>(`${path}`, body, headers).pipe(catchError(this.handleError.bind(this)));
  }

  public put<T>(path: string, body: any, options?: any): any {
    return this.http.put<T>(`${path}`, body, options).pipe(catchError(this.handleError));
  }
  public patch<T>(path: string, body: any, options?: any): any {
    return this.http.patch<T>(`${path}`, body, options).pipe(catchError(this.handleError.bind(this)));
  }
  public delete<T>(path: string, options?: any): any {
    return this.http.delete<T>(`${path}`, options).pipe(catchError(this.handleError.bind(this)));
  }

  // Error handling
  private handleError(error: any) {
    let errorMessage = 'Something went wrong, try again later.';
    if (error.status === 500) {
      errorMessage = this.getErrorMessage(error);
    } else if (error.status >= 400 && error.status < 500) {
      errorMessage = this.getErrorMessage(error) || this.getErrorMessage(error.error);
    }
    this.snackBarService.openFailure(`${errorMessage}`, 'OK', {
      duration: 3000,
    });
    return throwError(errorMessage);
  }

  private getErrorMessage(error: any) {
    return error?.errors?.[0]?.msgs?.[0] || error?.error?.message;
  }

  public logout(): void {
    this.dataService.clearUserData();
    const baseDomain = environment.URL_DOMAIN;
    this.cookieService.deleteAll('/', baseDomain);
    this.document.location.href = environment.URL_LOGIN;
  }
}
