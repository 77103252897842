import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-snackbar-failure',
  templateUrl: './snackbar-failure.component.html',
  styleUrls: ['./snackbar-failure.component.scss'],
})
export class SnackbarFailureComponent {
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  text: string;
  textButton: string;
  title: string;

  close(): void {
    this.closeEvent.emit();
  }
}
