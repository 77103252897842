<div class="confirm-modal">
  <div class="confirm-modal__container">
    <div class="confirm-modal__icon-container" [ngStyle]="{ background: messageData?.backgroundColor }">
      <mat-icon
        [ngStyle]="{ color: messageData?.color }"
        *ngIf="!messageData?.isSymbols"
        [ngClass]="messageData?.iconClass ? messageData?.iconClass : 'material-icons-solid'"
        >{{ messageData?.icon || 'info' }}</mat-icon
      >
      <span *ngIf="messageData?.isSymbols" class="material-symbols-rounded">{{ messageData?.icon || 'info' }}</span>
    </div>
    <div class="confirm-modal__content">
      <div class="confirm-modal__content--title">
        {{ messageData?.title | titlecase }}
      </div>
      <div class="confirm-modal__content--message">
        <span>{{ messageData?.message }}</span>
        <ul *ngIf="messageData?.renderList">
          <li *ngFor="let item of messageData.renderList">
            {{ item | titlecase }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="confirm-modal__footer">
    <button class="confirm-modal__footer--cancel" mat-button (click)="close()">
      {{ messageData?.cancelButtonName || 'CANCEL' | uppercase }}
    </button>
    <button class="confirm-modal__footer--save" [ngStyle]="{ background: messageData?.color }" mat-button (click)="confirm()">
      {{ messageData?.okButtonName || 'YES' | uppercase }}
    </button>
  </div>
</div>
