<div class="error-modal">
  <div class="error-modal__container">
    <div class="error-modal__icon-container">
      <mat-icon [ngClass]="messageData?.iconClass ? messageData?.iconClass : 'material-icons-solid'">{{
        messageData?.icon || 'error'
      }}</mat-icon>
    </div>
    <div class="error-modal__content">
      <div class="error-modal__content--title">
        {{ messageData?.title | titlecase }}
      </div>
      <div class="error-modal__content--message">
        {{ messageData?.message }}
      </div>
    </div>
  </div>
  <div class="error-modal__footer">
    <button mat-button (click)="close()">
      {{ messageData?.buttonName || 'Ok' | uppercase }}
    </button>
  </div>
</div>
