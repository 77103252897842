export const TIME_MILLISECONDS_SNACKBAR = 5000;
export const TIME_TICK = 1000;
export const TIME_LIMIT_MIN_AUDIO = 60;
export const TIME_LIMIT_MAX_AUDIO = 119;
export const REGEX_LETTERS_AND_NUMBERS = '[a-zA-Z0-9]*';
export const REGEX_LETTERS_MAYUS_AND_NUMBERS = '[A-Z0-9]*';
export const REGEX_STREET_ADDRESS =
  /^(autop(ista)?|calle|carrera|circunvalar|diagonal|kil(o|ó)metro|trans(versal)?|av(\.|enida)?((\s)+(cl(l)?|[ck]r(a)?|calle|carrera|circunvalar))?|cl(l)?(\.?)|[ck]r(a)?(\.)?|ac|ak|ccv|dg|km|tv)(\s)+(\d{1,4}[\°\ª]?|\D{0,20})(\s)?(\s)?(Este|Norte|Occidente|Oeste|Sur)?(\s)?(bis)?(\s)?(trans(versal)?)?(\s)?[a-zA-Z]{0,7}[\°\ª]?(\s)?(bis)?(calle|carrera|cl(l)?(\.?)|[ck]r[ar]?(\.)?|\#|n([\º\°\ªo])?\.?)?(\s)?(\d){1,4}(\s)*([a-z]|°|ª)?((\s)+(Este|Norte|Occidente|Oeste|Sur))?(\s)?(\-|\–|\─)?(\s)?(\d){1,4}([a-z]|°|ª)?((\s)+(Este|Norte|Occidente|Oeste|Sur))?(\s?(agrupaci(o|ó)?n|altillo|ap(to\.?|t\.?|\.|artamento)?((\s)+(s(o|ó)?tano))?|barrio|bloque|bodega|cabecera municipal|callej(o|ó)?n|camino|carretera|casa|caserio|c(e|é)?lula|centro((\s)+(comercial|urbano))?|circular|condominio|conjunto|consultorio|corregimiento|dep(o|ó)?sito(\s)?|s(o|ó)?tano|edificio|entrada|esquina|etapa((\s)+\d{0,3})?|finca|garaje((\s)+(s(o|ó)?tano))?|grada|inferior|inspecci(o|ó)?n de polic(i|í)?a|interior|kil(o|ó)?metro|local((\s)+(mezzanine|s(o|ó)?tano))?|lote|manzan(a|ita)|mejora|mezzanine|m(o|ó)?dulo|mun(icipio)?|n(u|ú)?cleo|oficina((\s)+(s(o|ó)?tano))?|parcela(ci(o|ó)?n)?|pasaje|penthouse|piso((\s)+\d{0,3})?|portería|predio|principal|puente|quebrada|sal(o|ó)?n|sector|semis(o|ó)?tano|suite|supermanzana|terraza|to(rre\.?|\.)?|troncal|unidad|urbanizaci(o|ó)?n|vereda|v(i|í)?a|zona|agn|al|as|br|bl|bg|cm|clj|cn|ct|ca|cas|cel|ce|ceco|ceur|cir|cdm|conj|cs|co|dp|ds|ed|en|esq|et|fca|gj|gs|gr|inf|ip|in|km|lc|lm|ls|lt|mz|mzta|mj|mn|md|nco|of|os|pa|pcn|psj|ph|pi|pt|pd|ppal|pn|qda|sa|sec|ss|su|smz|tz|to|trl|un|urb|vda|zn)[\.\,]?\s?[1-9](\-|\–|\─)?[0-9]{0,3}[\.\,]?)*/gim;
export const REGEX_ONLY_NUMBERS = '[0-9]*';
export const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

export const MAX_CHARACTERS_CODE = 6;
export const MIN_CHARACTERS_CODE = 6;

export const CONTINUE_PROCESS = 'continueProcess';
export const VALIDATION_CODE = 'validationCode';

export const LIMIT_SKILLS = 6;
export const LIMIT_SKILLS_VALIDATE = 6;
export const LIMIT_SOFTWARE = 6;
export const LIMIT_SOFTWARE_VALIDATE = 6;
export const LIMIT_INTEREST = 6;
export const LIMIT_LANGUAGE = 6;
export const ENGLISH_LEVEL_A1 = 1;
export const LIMIT_DROPDOWNS = 6;

export const IDENTIFICATOR_UPDATE_FIELDS_MY_PROFILE = 'pr_';

// Stepper
export const STEPPER_KEYS = ['personalInformation', 'myProfile', 'educationalInformation', 'workExperience'];
export const STEPPER_CONFIG = {
  personalInformation: {
    complete: true,
    active: true,
    icon: 'assets/icons/stepper/personalInformation-inactive.svg',
  },
  myProfile: {
    complete: true,
    active: false,
    icon: 'assets/icons/stepper/myProfile-inactive.svg',
  },
  educationalInformation: {
    complete: true,
    active: false,
    icon: 'assets/icons/stepper/educationalInformation-inactive.svg',
  },
  workExperience: {
    complete: true,
    active: false,
    icon: 'assets/icons/stepper/workExperience-inactive.svg',
  },
};

export const COLOR_SPINNER = '#4830CE';
export const BDCOLOR_SPINNER = 'rgba(255, 255, 255, 0.8)';

export const TABS_SUMMARY = [
  {
    value: ' ',
    disabled: true,
  },
  {
    value: 'PROGRESS',
    disabled: false,
  },
  {
    value: 'CURRICULUM',
    disabled: false,
  },
  {
    value: ' ',
    disabled: true,
  },
];

export const FORMAT_DATE_LANGUAGE = 'en';
export const FORMAT_DATE_DEFAULT = [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' }];

export const RESOLUTION_IMAGE_UPLOAD = 300;

export const KEY_BOGOTA_CITY = 'Bogotá';
export const KEY_BOGOTA_CITY_WITHOUT_MARK = 'Bogota';
export const KEY_BOGOTA_STATE = 'Cundinamarca';

export const EDUCATION_LEVEL_SCHOOL_HIGH_SCHOOL = 'school/high school';
