import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '@shared/components/snackbar-success/snackbar-success.component';
import { SnackbarConfirmComponent } from '@shared/components/snackbar-confirm/snackbar-confirm.component';
import { take } from 'rxjs/operators';
import { SnackbarFailureComponent } from '@shared/components/snackbar-failure/snackbar-failure.component';
import { SnackbarWarningComponent } from '@shared/components/snackbar-warning/snackbar-warning.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private DURATION = 3000;

  constructor(private snackBar: MatSnackBar) {}

  openSuccess(text: string, textButton: string, config?: MatSnackBarConfig): MatSnackBarRef<SnackbarSuccessComponent> {
    const reference = this.snackBar.openFromComponent(SnackbarSuccessComponent, {
      ...config,
      panelClass: 'success-snackbar',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: config?.duration ? config.duration : this.DURATION,
    });
    reference.instance.text = text;
    reference.instance.textButton = textButton;
    reference.instance.closeEvent.pipe(take(1)).subscribe(() => reference.dismiss());
    return reference;
  }

  openInfo(text: string, okTextButton: string, config?: MatSnackBarConfig): MatSnackBarRef<SnackbarConfirmComponent> {
    const ref = this.snackBar.openFromComponent(SnackbarConfirmComponent, {
      ...config,
      panelClass: 'confirmation-snackbar',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: config?.duration ? config.duration : this.DURATION,
    });
    ref.instance.text = text;
    ref.instance.okTextButton = okTextButton;
    ref.instance.closeEvent.pipe(take(1)).subscribe(() => ref.dismiss());
    return ref;
  }

  openFailure(text: string, textButton: string, config: MatSnackBarConfig = {}, title = 'Error') {
    const reference = this.snackBar.openFromComponent(SnackbarFailureComponent, {
      ...config,
      panelClass: 'error-snackbar',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: config?.duration ? config.duration : this.DURATION,
    });
    reference.instance.title = title;
    reference.instance.text = text;
    reference.instance.textButton = textButton;
    reference.instance.closeEvent.pipe(take(1)).subscribe(() => reference.dismiss());
    return reference;
  }

  openWarning(text: string, textButton: string, config?: MatSnackBarConfig) {
    const reference = this.snackBar.openFromComponent(SnackbarWarningComponent, {
      ...config,
      panelClass: 'warning-snackbar',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: config?.duration ? config.duration : this.DURATION,
    });
    reference.instance.text = text;
    reference.instance.textButton = textButton;
    reference.instance.closeEvent.pipe(take(1)).subscribe(() => reference.dismiss());
    return reference;
  }
}
