import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from '@services/spinner/spinner.service';
import { environment } from '@environments/environment';
import _get from 'lodash/get';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private ngxSpinnerService: NgxSpinnerService, private spinnerService: SpinnerService) {}

  // Intercept the request to set header.
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    const ulrReadC = `${environment.API_DOMAIN}/public-rest/v1/prospect/prospects/extract-cv-information/`;
    if (req.method === 'POST' && ulrReadC !== _get(req, 'url', '')) {
      this.ngxSpinnerService.show('globalLoading');
    }

    const params = {
      'Content-Language': 'en-us',
    };

    req = req.clone({
      setHeaders: params,
    });

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.hide();
            const spinner = this.ngxSpinnerService.getSpinner('globalLoading');
            if (spinner) {
              this.ngxSpinnerService.hide('globalLoading');
            }
          }
        },
        () => {
          this.spinnerService.hide();
          const spinner = this.ngxSpinnerService.getSpinner('globalLoading');
          if (spinner) {
            this.ngxSpinnerService.hide('globalLoading');
          }
        }
      )
    );
  }
}
