import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HaveToCandidatePasswordGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { personId, hasPassword } = this.authenticationService.getCookie('user');
    if (hasPassword === false && personId) return true;
    this.router.navigateByUrl('/assigned-candidate-password');
    return false;
  }
}
