import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HavePersonIdGuard } from '@shared/guards/have-person-id/have-person-id.guard';
import { HaveToAcceptPrivacyGuard } from '@shared/guards/have-to-accept-privacy/have-to-accept-privacy.guard';
import { HaveToCandidatePasswordGuard } from '@shared/guards/have-to-candidate-password/have-to-candidate-password.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/main-layout/main-layout.module').then((m) => m.MainLayoutModule),
  },
  {
    path: 'portal',
    loadChildren: () => import('./main/landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'verify-policy',
    canActivate: [HaveToAcceptPrivacyGuard],
    loadChildren: () => import('./main/verify-privacy-policy/verify-privacy-policy.module').then((m) => m.VerifyPrivacyPolicyModule),
  },

  {
    path: 'assigned-candidate-password',
    canActivate: [HaveToCandidatePasswordGuard],
    loadChildren: () =>
      import('./main/assigned-candidate-password/assigned-candidate-password.module').then((m) => m.AssignedCandidatePasswordModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./main/login-layout/login-layout.module').then((m) => m.LoginLayoutModule),
  },
  {
    path: 'accounts/google/login/callback',
    loadChildren: () => import('./main/login-layout/auth-google/auth-google.module').then((m) => m.AuthGoogleAuthModule),
  },
  {
    path: 'accounts/linkedin/login/callback',
    loadChildren: () => import('./main/login-layout/auth-linkend-in/auth-linkend-in.module').then((m) => m.AuthLinkendInModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./main/register-layout/register-layout.module').then((m) => m.RegisterLayoutModule),
  },
  {
    path: 'validate-redirection',
    loadChildren: () => import('./main/validate-redirection/validate-redirection.module').then((m) => m.ValidateRedirectionModule),
  },
  {
    path: 'applicant',
    canActivate: [HavePersonIdGuard],
    loadChildren: () => import('./main/applicant/applicant.module').then((m) => m.ApplicantModule),
  },
  {
    path: 'applicant-portal',
    canActivate: [HavePersonIdGuard],
    loadChildren: () => import('./main/applicant-portal/applicant-portal.module').then((m) => m.ApplicantPortalModule),
  },
  { path: '404', loadChildren: () => import('./main/404/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule) },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
