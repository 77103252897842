<div class="snackbar-info">
  <div class="message-container">
    <img src="/assets/images/snack-bar/info.svg" alt="success icon" />
    <div class="message">
      <div class="message__title">Information</div>
      <div class="message__text">{{ text | capitalLetter }}</div>
    </div>
  </div>
  <div class="actions">
    <button mat-icon-button class="close-icon" aria-label="Close snackbar" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
