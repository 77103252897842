import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApplicantFormService } from '@services/applicant-form/applicant-form.service';
import {
  updateEducationalInformation,
  updateEducationalInformationPercentage,
  updateMyProfile,
  updateMyProfilePercentage,
  updatePersonalInformation,
  updatePersonalInformationPercentage,
  updateWorkExperience,
  updateWorkExperiencePercentage,
  getEnglishLevel,
  getSkills,
  getLanguages,
  getInterests,
  getVisaTypes,
  getGenders,
  getDocumentTypes,
  getMaritalStatuses,
  getContactRelationships,
} from '@app-core/store/actions/applicant-form/applicant-form.actions';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { Store } from '@ngrx/store';
import { GeneralService } from '@services/general/general.service';
import _get from 'lodash/get';
import { sortValidatorName } from '@shared/validators/orderNameValidator.validator';
import { GeneralReloadedService } from '@services/general/general-reloaded.service';
@Injectable()
export class ApplicantFormEffect {
  updatePercentage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePersonalInformation.type, updateMyProfile.type, updateEducationalInformation.type, updateWorkExperience.type),
      mergeMap(() => {
        const userData = this.authenticationService.getCookie('user');
        if (userData.personId) {
          return this.applicantFormService.getFormPercentage(userData.personId).pipe(
            map((percentage) => {
              const result = percentage[0];
              this.store.dispatch(updatePersonalInformationPercentage({ percentage: result.personal }));
              this.store.dispatch(updateMyProfilePercentage({ percentage: result.profile }));
              this.store.dispatch(updateEducationalInformationPercentage({ percentage: result.education }));
              this.store.dispatch(updateWorkExperiencePercentage({ percentage: result.work_information }));
              return { type: '[Applicant Form] updateTotalPercentage', percentage: result.percentage };
            }),
            catchError(() => EMPTY)
          );
        } else {
          return EMPTY;
        }
      })
    )
  );

  getDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentTypes.type),
      mergeMap(() =>
        this.generalService.getDocumentTypes().pipe(
          map((result) => {
            const genders = _get(result, 'results', []);
            return { type: '[Applicant Form] updateDocumentTypes', payload: genders };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getGenders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGenders.type),
      mergeMap(() =>
        this.generalReloadedService.getGenders().pipe(
          map((result) => {
            return { type: '[Applicant Form] updateGenders', payload: result };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getMaritalStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMaritalStatuses.type),
      mergeMap(() =>
        this.generalService.getMaritalStatuses().pipe(
          map((result) => {
            const genders = _get(result, 'marital_statuss', []);
            return { type: '[Applicant Form] updateMaritalStatuses', payload: genders };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getContactRelationships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactRelationships.type),
      mergeMap(() =>
        this.generalReloadedService.getContactRelationships().pipe(
          map((result) => {
            const contactRelationships = _get(result, 'results', []);
            return { type: '[Applicant Form] updateContactRelationships', payload: contactRelationships };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSkills.type),
      mergeMap(({ payload }) =>
        this.generalService.getSkills(payload).pipe(
          map((result) => {
            result.skills.sort(sortValidatorName);
            const skills = _get(result, 'skills', []);
            return { type: '[Applicant Form] updateSkills', payload: skills };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLanguages.type),
      mergeMap(() =>
        this.generalService.getLanguages().pipe(
          map((result) => {
            const languages = _get(result, 'languages', []);
            return { type: '[Applicant Form] updateLanguages', payload: languages };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getEnglishLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEnglishLevel.type),
      mergeMap(() =>
        this.generalService.getEnglishLevels().pipe(
          map((result) => {
            const englishLevels = _get(result, 'english_level', []);
            return { type: '[Applicant Form] updateEnglishLevel', payload: englishLevels };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getInterests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInterests.type),
      mergeMap(({ payload }) =>
        this.generalService.getHobbies(payload).pipe(
          map((result) => {
            result.InterestAndHobby.sort(sortValidatorName);
            const interests = _get(result, 'InterestAndHobby', []);
            return { type: '[Applicant Form] updateInterests', payload: interests };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getVisaTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVisaTypes.type),
      mergeMap(() =>
        this.generalService.getVisaTypes().pipe(
          map((result) => {
            const visaTypes = _get(result, 'visa_type', []);
            return { type: '[Applicant Form] updateVisaTypes', payload: visaTypes };
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private applicantFormService: ApplicantFormService,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
    private generalReloadedService: GeneralReloadedService
  ) {}
}
