<div class="mobile-confirm-message-modal">
  <div class="mobile-confirm-message-modal__close">
    <mat-icon class="material-symbols-outlined" (click)="close()"> close </mat-icon>
  </div>
  <div class="mobile-confirm-message-modal__container">
    <section style="display: flex; justify-content: center; align-items: center">
      <div class="mobile-confirm-message-modal__icon-container" [ngStyle]="{ background: messageData?.backgroundColor }">
        <mat-icon
          [ngStyle]="{ color: messageData?.color }"
          *ngIf="!messageData?.isSymbols"
          [ngClass]="messageData?.iconClass ? messageData?.iconClass : 'material-icons-solid'"
          >{{ messageData?.icon || 'info' }}</mat-icon
        >
        <span *ngIf="messageData?.isSymbols" class="material-symbols-rounded">{{ messageData?.icon || 'info' }}</span>
      </div>
    </section>

    <section style="display: flex; justify-content: center; align-items: center">
      <div class="mobile-confirm-message-modal__content">
        <div class="mobile-confirm-message-modal__content--title">
          {{ messageData?.title }}
        </div>
        <div class="mobile-confirm-message-modal__content--message">
          <span>{{ messageData?.message }}</span>
          <ul *ngIf="messageData?.renderList">
            <li *ngFor="let item of messageData.renderList">
              {{ item | titlecase }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
  <div class="mobile-confirm-message-modal__footer">
    <button
      class="mobile-confirm-message-modal__footer--save"
      [ngStyle]="{ background: messageData?.color }"
      mat-button
      (click)="confirm()"
    >
      {{ messageData?.okButtonName || 'YES' | uppercase }}
    </button>
    <button class="mobile-confirm-message-modal__footer--cancel" mat-button (click)="close()">
      {{ messageData?.cancelButtonName || 'CANCEL' | uppercase }}
    </button>
  </div>
</div>
