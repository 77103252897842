import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmMessageModalComponent } from '@shared/components/message-modals/confirm-message-modal/confirm-message-modal.component';
import { MessageModal } from '@services/message-modal/message-modal';
import { ErrorMessageModalComponent } from '@shared/components/message-modals/error-message-modal/error-message-modal.component';
import { MobileConfirmMessageModalComponent } from '@shared/components/message-modals/mobile-confirm-message-modal/mobile-confirm-message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MessageModalService {
  constructor(private dialog: MatDialog) {}

  /**
   * Fires the error message modal with the passed data
   * @param data
   */
  showErrorMessage(data: MessageModal): MatDialogRef<any> {
    return this.dialog.open(ErrorMessageModalComponent, {
      panelClass: 'message-modal-error',
      data,
      disableClose: true,
    });
  }

  /**
   * Fires the confirmation message modal with the passed data
   * @param data
   */
  showConfirmMessage(data: MessageModal): MatDialogRef<any> {
    return this.dialog.open(ConfirmMessageModalComponent, {
      panelClass: 'message-modal-confirm',
      data,
      disableClose: true,
    });
  }

  showConfirmMobileMesage(data: MessageModal): MatDialogRef<any> {
    return this.dialog.open(MobileConfirmMessageModalComponent, {
      panelClass: 'mobile-confirm-message-modal',
      data,
      disableClose: true,
    });
  }
}
