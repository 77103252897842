import { logOut } from '../actions/applicant-form/applicant-form.actions';

export const clearState = (reducer) => {
  return (state, action) => {
    if (action.type === logOut.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
};
