import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['./snackbar-success.component.scss'],
})
export class SnackbarSuccessComponent implements OnInit {
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  text: string;
  textButton: string;
  title: string;

  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    if (this.router.url.includes('verificateCode')) {
      this.title = this.translate.currentLang === 'en' ? 'Sent' : 'Enviado';
      return;
    }
    this.title = this.translate.currentLang === 'en' ? 'Success' : 'Exitoso';
  }

  close() {
    this.closeEvent.emit();
  }
}
