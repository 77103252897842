import { ApplicantPortalState } from '@app-core/store/appState.model';
import { Action, createReducer, on } from '@ngrx/store';
import { updateProgressSidebar } from '@app-core/store/actions/applicant-portal/applicant-portal.actions';

export const initialState: ApplicantPortalState = {
  updatedData: '',
};

const reducer = createReducer(
  initialState,
  on(updateProgressSidebar, (state, action) => ({ ...state, updatedData: action.payload }))
);

export function applicantPortalReducer(state: ApplicantPortalState | undefined, action: Action) {
  return reducer(state, action);
}
