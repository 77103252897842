import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-snackbar-confirm',
  templateUrl: './snackbar-confirm.component.html',
  styleUrls: ['./snackbar-confirm.component.scss'],
})
export class SnackbarConfirmComponent {
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  text: string;
  okTextButton: string;
  cancelTextButton: string;

  close() {
    this.closeEvent.emit();
  }
}
