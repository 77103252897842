import { applicantFormMyPersonalInformation, applicantFormMyProfile, LanguagesMyProfile } from '@app-core/store/appState.model';
import _pick from 'lodash/pick';
import _values from 'lodash/values';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { ENGLISH_LEVEL_A1, FORMAT_DATE_DEFAULT, FORMAT_DATE_LANGUAGE } from '@shared/const/generals.const';

export const validatorsFieldsRequiredPersonalInformation = (personalInformation: applicantFormMyPersonalInformation) => {
  const personalInformationData = _pick(personalInformation, [
    'cv',
    'name',
    'last_name',
    'identity_document_type',
    'identity_document',
    'gender',
    'mobile_phone',
    'email',
    'marital_status',
    'birth_date',
    'birth_state',
    'birth_city',
    'birth_country',
    'address_country',
    'address_state',
    'address_city',
    'address',
  ]);
  const values: Array<any> = _values(personalInformationData);
  if (_size(values) > 0) {
    const someValueIsEmpty = values.some((value) => !value);
    return !someValueIsEmpty;
  }
  return false;
};

export const validatorsFieldsRequiredMyProfile = (myProfile: applicantFormMyProfile) => {
  let fieldsValidation = ['about_me', 'skills', 'english_level', 'audio', 'visa_type', 'profile_languages', 'interests'];
  let myProfilenData = _pick(myProfile, fieldsValidation);
  const level = _get(myProfilenData, 'english_level', '');
  if (level === ENGLISH_LEVEL_A1) {
    fieldsValidation = ['about_me', 'skills', 'english_level', 'visa_type', 'profile_languages', 'interests'];
    myProfilenData = _pick(myProfile, fieldsValidation);
  }
  const visaType = _get(myProfilenData, 'visa_type', 0);
  if (visaType && visaTypeValidation(visaType)) {
    fieldsValidation.push('visa_expiration_date');
    myProfilenData = _pick(myProfile, fieldsValidation);
  }
  const values: Array<any> = _values(myProfilenData);
  if (_size(values) > 0) {
    const someValueIsEmpty = values.some((value) => {
      if (typeof value === 'object') {
        return _size(value) === 0;
      }
      return !value;
    });
    return !someValueIsEmpty;
  }
  return false;
};

export const validatorEducationalInformation = (educationalInformation: any) => !_isEmpty(educationalInformation);
export const validatorWorkExperiences = (workExperiences: any) => !_isEmpty(workExperiences);

export const visaTypeValidation = (visaType: number): boolean => {
  if (visaType) {
    if (visaType !== 2 && visaType !== 4) {
      return false;
    }
  }
  return true;
};

/**
 * This method reestructure all data to show in the view
 * @param myProfile data my profile
 * @returns - Return object with mapped data
 */
export const mapDataMyProfile = (myProfile) => {
  let skills: Array<string> = _get(myProfile, 'skills', []) || [];
  skills = [...skills.map((skill) => _get(skill, 'name', ''))];
  const skillsSelected: Array<string> = _get(myProfile, 'skills', []) || [];
  let profile_languages: Array<LanguagesMyProfile> = _get(myProfile, 'profile_languages', []);
  profile_languages = profile_languages.map((language) => _get(language, 'language', ''));
  let languagesSelected: Array<any> = _get(myProfile, 'profile_languages', []) || [];
  languagesSelected = languagesSelected.map((language) => ({ id: language.language, name: language.name_language }));
  let interests: Array<string> = _get(myProfile, 'interests', []) || [];
  interests = [...interests.map((interest) => _get(interest, 'name', ''))];
  const interestsSelected: Array<string> = _get(myProfile, 'interests', []) || [];
  return _cloneDeep({
    ...myProfile,
    skills,
    skillsSelected,
    profile_languages,
    languagesSelected,
    interests,
    interestsSelected,
  });
};

export const formatDate = (date: Date | number, formatDate: Array<any> = FORMAT_DATE_DEFAULT, separator = '-') => {
  const format = (m) => {
    const f = new Intl.DateTimeFormat(FORMAT_DATE_LANGUAGE, m);
    return f.format(date);
  };
  return formatDate.map(format).join(separator);
};

export const convertBase64ToFormData = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const mapLocation = (arrayList): Array<any> => {
  return arrayList.map((item) => {
    const city__name = (item && item.city__name) || '';
    const city__state__name = (item && item.city__state__name) || '';
    const city__state__country__name = (item && item.city__state__country__name) || '';
    return { ...item, city: city__name, state: city__state__name, country: city__state__country__name };
  });
};

export const noWriteNumbers = (value) => {
  let text = value;
  text = text.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
  return text;
};

/**
 * Calculate height viewport in specific element
 * @param elementClassName - String
 */
export const calculateHeightWindow = (elementClassName: string) => {
  const element = document.querySelector(elementClassName);
  if (element) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
};

/**
 * This is use to generate some action in specific input
 * @param id id elementinput
 * @param type type event
 */
export const eventsElement = (id, type = 'focus') => {
  const element: HTMLElement = document.querySelector(`input[id=${id}]`);
  if (element) {
    if (type === 'focus') {
      element.focus();
    } else if (type === 'click') {
      element.click();
    }
  }
};
