import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import _get from 'lodash/get';
import { applicantFormMyPersonalInformation } from '@app-core/store/appState.model';
import { mapLocation } from '@shared/methods/methods';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicantFormService {
  PATH = `${environment.API_DOMAIN_RELOADED}/api/v1`;
  constructor(private httpService: HttpService, private cookieService: CookieService) {}

  getApplicantInformation(code): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/profile_applicant/`;
    const params = new HttpParams().set('code', code);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get(path, { params, headers }).pipe(
      map((data) => {
        const detailPerson = this.mapDetailPersonData(data);
        return {
          profilePicture: detailPerson.photo,
          personalInformation: detailPerson,
          myProfile: {
            about_me: _get(data, 'about_me', ''),
            skills: _get(data, 'skills', []),
            english_level: _get(data, 'english_level', ''),
            audio: _get(data, 'audio', ''),
            visa_type: _get(data, 'visa_type', ''),
            available_to_travel: _get(data, 'available_to_travel', ''),
            visa_expiration_date: _get(data, 'visa_expiration_date', ''),
            driver_license: _get(data, 'driver_license', ''),
            availability: _get(data, 'availability', ''),
            profile_languages: _get(data, 'profile_languages', []),
            interests: _get(data, 'interests', []),
          },
          educationalInformation: mapLocation(_get(data, 'education', [])).map((item) => {
            const education_level = (item && item.education_level__name) || '';
            return { ...item, education_level };
          }),
          workExperiences: mapLocation(_get(data, 'work', [])),
          curriculum: data['cv'],
        };
      })
    );
  }

  getFormPercentage(applicantId): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/percentage/${applicantId}`;
    return this.httpService.get(path);
  }

  saveProfileInfo(applicantId, payload): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/${applicantId}/`;
    return this.httpService.patch(path, payload);
  }

  saveEducationalInformation(applicantId, payload): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/education_profile/${applicantId}/`;
    return this.httpService.post(path, payload);
  }

  updateEducationalInformation(applicantId, educationalId, payload): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/education_profile/${applicantId}/${educationalId}/`;
    return this.httpService.put(path, payload);
  }

  deleteEducationalInformation(personId, id) {
    const path = `${this.PATH}/recruitment/new/aplicant/education_profile/${personId}/${id}`;
    return this.httpService.delete<any>(path);
  }

  getEducationLevels(): Observable<any> {
    const path = `${this.PATH}/general/educationlevel/`;
    return this.httpService.get<any>(path);
  }

  getDisciplineAcademic(): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/career/`;
    return this.httpService.get<any>(path).pipe(map((result) => _get(result, 'career', [])));
  }

  mapDetailPersonData(data): applicantFormMyPersonalInformation {
    const detail_person = _get(data, 'detail_person', null);
    return detail_person
      ? {
          cv: _get(data, 'cv', ''),
          photo: _get(detail_person, 'photo', ''),
          name: _get(detail_person, 'name', ''),
          last_name: _get(detail_person, 'last_name', ''),
          identity_document: _get(detail_person, 'identity_document', ''),
          identity_document_type: _get(detail_person, 'identity_document_type', ''),
          gender: _get(detail_person, 'gender', ''),
          mobile_phone: _get(detail_person, 'mobile_phone', ''),
          email: _get(detail_person, 'email', ''),
          marital_status: _get(detail_person, 'marital_status', ''),
          birth_date: _get(detail_person, 'birth_date', ''),
          birth_country: _get(detail_person, 'detail_birth_city.state.country.name', ''),
          birth_state: _get(detail_person, 'detail_birth_city.state.name', ''),
          birth_city: _get(detail_person, 'detail_birth_city.name', ''),
          address_country: _get(detail_person, 'detail_address_city.state.country.name', ''),
          address_state: _get(detail_person, 'detail_address_city.state.name', ''),
          address_city: _get(detail_person, 'detail_address_city.name', ''),
          neighborhood: _get(detail_person, 'neighborhood', ''),
          address: _get(detail_person, 'address', ''),
          zipcode: _get(detail_person, 'zipcode', ''),
        }
      : {
          cv: _get(data, 'cv', ''),
          photo: _get(data, 'photo', ''),
          name: _get(data, 'name', ''),
          last_name: _get(data, 'last_name', ''),
          identity_document: _get(data, 'identity_document', ''),
          identity_document_type: _get(data, 'identity_document_type', ''),
          gender: _get(data, 'gender', ''),
          mobile_phone: _get(data, 'mobile_phone', ''),
          email: _get(data, 'email', ''),
          marital_status: _get(data, 'marital_status', ''),
          birth_date: _get(data, 'birth_date', ''),
          birth_country: _get(data, 'detail_birth_city.state.country.name', ''),
          birth_state: _get(data, 'detail_birth_city.state.name', ''),
          birth_city: _get(data, 'detail_birth_city.name', ''),
          address_country: _get(data, 'detail_address_city.state.country.name', ''),
          address_state: _get(data, 'detail_address_city.state.name', ''),
          address_city: _get(data, 'detail_address_city.name', ''),
          neighborhood: _get(data, 'neighborhood', ''),
          address: _get(data, 'address', ''),
          zipcode: _get(data, 'zipcode', ''),
        };
  }

  saveCurriculum(applicantId, body): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/${applicantId}`;
    return this.httpService.patch(path, body);
  }

  updateFieldProfile(applicantId, payload): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/${applicantId}`;
    return this.httpService.patch(path, payload);
  }

  getWorkExperiences(): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/work_information/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<any>(path, headers);
  }

  createWorkExperience(personId, body) {
    const path = `${this.PATH}/recruitment/new/aplicant/work_information/${personId}/`;
    return this.httpService.post<any>(path, body);
  }

  updateWorkExperience(personId, id, body) {
    const path = `${this.PATH}/recruitment/new/aplicant/work_information/${personId}/${id}/`;
    return this.httpService.put<any>(path, body);
  }

  deleteWorkExperience(personId, id) {
    const path = `${this.PATH}/recruitment/new/aplicant/work_information/${personId}/${id}`;
    return this.httpService.delete<any>(path);
  }

  readCV(body): Observable<any> {
    const path = `${this.PATH}/recruitment/new/aplicant/read_cv/`;
    return this.httpService.post<any>(path, body);
  }

  logOutApplicant() {
    const baseDomain = environment.URL_DOMAIN;
    this.cookieService.deleteAll('/', baseDomain);
  }

  createCandidate(code) {
    const path = `${this.PATH}/recruitment/new/aplicant/create_candidate/?code=${code}`;
    return this.httpService.post<any>(path, {});
  }
}
