import { createAction, props } from '@ngrx/store';

export const updatePersonalInformation = createAction('[Applicant Form] updatePersonalInformation', props<{ payload: any }>());
export const updateMyProfile = createAction('[Applicant Form] updateMyProfile', props<{ payload: any }>());
export const updateEducationalInformation = createAction('[Applicant Form] updateEducationalInformation', props<{ payload: any }>());
export const updateWorkExperience = createAction('[Applicant Form] updateWorkExperience', props<{ payload: any }>());

export const updatePersonalInformationPercentage = createAction(
  '[Applicant Form] updatePersonalInformationPercentage',
  props<{ percentage: number }>()
);
export const updateMyProfilePercentage = createAction('[Applicant Form] updateMyProfilePercentage', props<{ percentage: number }>());
export const updateEducationalInformationPercentage = createAction(
  '[Applicant Form] updateEducationalInformationPercentage',
  props<{ percentage: number }>()
);
export const updateWorkExperiencePercentage = createAction(
  '[Applicant Form] updateWorkExperiencePercentage',
  props<{ percentage: number }>()
);
export const updateTotalPercentage = createAction('[Applicant Form] updateTotalPercentage', props<{ percentage: number }>());

export const updateProfilePicture = createAction('[Applicant Form] updateProfilePicture', props<{ profilePicture: any }>());

export const updateStepPosition = createAction('[Applicant Form] updateStepPosition', props<{ payload: any }>());

export const saveUserData = createAction('[Applicant Form] saveUserData', props<{ payload: any }>());

export const changeGenderPictureProfile = createAction('[Applicant Form] changeGenderPictureProfile', props<{ payload: number }>());
export const changeFullNamePictureProfile = createAction('[Applicant Form] changeFullNamePictureProfile', props<{ payload: string }>());

export const updateValidationMyPersonalInformation = createAction(
  '[Applicant Form] updateValidationMyPersonalInformation',
  props<{ payload: boolean }>()
);
export const updateValidationMyProfile = createAction('[Applicant Form] updateValidationMyProfile', props<{ payload: boolean }>());
export const updateValidationEducationInformation = createAction(
  '[Applicant Form] updateValidationEducationInformation',
  props<{ payload: boolean }>()
);
export const updateValidationWorkExperience = createAction(
  '[Applicant Form] updateValidationWorkExperience',
  props<{ payload: boolean }>()
);

export const updateAudioIsUploaded = createAction('[Applicant Form] updateAudioIsUploaded', props<{ payload: boolean }>());

export const getDocumentTypes = createAction('[Applicant Form] getDocumentTypes');
export const updateDocumentTypes = createAction('[Applicant Form] updateDocumentTypes', props<{ payload: Array<any> }>());

export const getGenders = createAction('[Applicant Form] getGenders');
export const updateGenders = createAction('[Applicant Form] updateGenders', props<{ payload: Array<any> }>());

export const getMaritalStatuses = createAction('[Applicant Form] getMaritalStatuses');
export const updateMaritalStatuses = createAction('[Applicant Form] updateMaritalStatuses', props<{ payload: Array<any> }>());

export const getSkills = createAction('[Applicant Form] getSkills', props<{ payload: Array<any> }>());
export const updateSkills = createAction('[Applicant Form] updateSkills', props<{ payload: Array<any> }>());

export const getLanguages = createAction('[Applicant Form] getLanguages');
export const updateLanguages = createAction('[Applicant Form] updateLanguages', props<{ payload: Array<any> }>());

export const getEnglishLevel = createAction('[Applicant Form] getEnglishLevel');
export const updateEnglishLevel = createAction('[Applicant Form] updateEnglishLevel', props<{ payload: Array<any> }>());

export const getInterests = createAction('[Applicant Form] getInterests', props<{ payload: Array<any> }>());
export const updateInterests = createAction('[Applicant Form] updateInterests', props<{ payload: Array<any> }>());

export const getContactRelationships = createAction('[Applicant Form] getContactRelationships');
export const updateContactRelationships = createAction('[Applicant Form] updateContactRelationships', props<{ payload: Array<any> }>());

export const getVisaTypes = createAction('[Applicant Form] getVisaTypes');
export const updateVisaTypes = createAction('[Applicant Form] updateVisaTypes', props<{ payload: Array<any> }>());
export const updateProgressInformation = createAction('[Applicant Progress] updateProgressInformation', props<{ payload: any }>());

export const isEditing = createAction('[Applicant Progress] isEditing', props<{ payload: boolean }>());
export const isEditFromCurriculum = createAction('[Applicant Progress] isEditFromCurriculum', props<{ payload: boolean }>());

export const isCandidate = createAction('[Applicant Progress] isCandidate', props<{ payload: any }>());

export const logOut = createAction('[Applicant LogOut] LogOut');
