// ANGULAR MODULES
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';

// CUSTOM AND THIRD PARTY MODULES
import { AppRoutingModule } from './app-routing.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

// COMPONENTS
import { AppComponent } from './app.component';

// OTHERS
import { httpInterceptorProviders } from '@app-core/http/interceptors';
import { StoreModule } from '@ngrx/store';
import { appStoreReducers } from '@app-core/store/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AgmCoreModule } from '@agm/core';
import { EffectsModule } from '@ngrx/effects';
import { ApplicantFormEffect } from '@app-core/store/effects/applicant-form.effect';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { clearState } from '@app-core/store/metareducers/applicantForm.metareducer';
import { GraphQLModule } from './graphql.module';
import { MsalModule, MsalService, MSAL_INSTANCE, MsalGuardConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { CommonModule } from '@angular/common';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from '@shared/class/zenDesk.class';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.AZURE_APP_ID,
      redirectUri: environment.URL_LOGIN,
    },
  });
}

export function MSALInstanceFactoryV2(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MICROSOFT_ACCOUNT.CLIENT_ID,
      redirectUri: environment.URL_LOGIN,
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['openid', 'email', 'profile'],
    },
  };
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RecaptchaModule,
    CommonModule,
    RecaptchaFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatDialogModule,
    GoogleTagManagerModule.forRoot({
      id: environment.TAG_MANAGER,
    }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    StoreModule.forRoot(appStoreReducers, { metaReducers: [clearState] }),
    StoreDevtoolsModule.instrument({ logOnly: true }),
    EffectsModule.forRoot([ApplicantFormEffect]),
    AgmCoreModule.forRoot({
      language: 'en',
      apiKey: environment.GOOGLE_API,
      libraries: ['places'],
      apiVersion: 'quarterly',
    }),
    GraphQLModule,
    MsalModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
  ],
  providers: [
    httpInterceptorProviders,
    CookieService,
    { provide: 'googleTagManagerId', useValue: environment.TAG_MANAGER },
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactoryV2,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.RECAPTCHA.SITE_KEY,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
