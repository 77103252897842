export const environment = {
  "API_DOMAIN": "https://api.leangroup.com",
  "API_DOMAIN_RELOADED": "https://api.leangroup.com",
  "API_DOMAIN_RELOADED_REGISTER": "https://api.leangroup.com",
  "API_PATH_PREFIX": "rest",
  "APPS_URL": {
    "ADMIN": "https://admin.leangroup.com/",
    "BILLING": "https://billing.leangroup.com/",
    "EMPLOYEES": "https://employees.leangroup.com/",
    "HR": "https://hr.leangroup.com/",
    "OPERATIONS": "https://operation.leangroup.com/",
    "QUALITY": "https://quality.leangroup.com/",
    "RECRUITMENT": "https://recruitment.leangroup.com/",
    "TRAINING": "https://training.leangroup.com/"
  },
  "AWS_GEO_ACCESS_KEY": "AKIAX7NWKBXMPAJATGNQ",
  "AWS_GEO_SECRET_ACCESS_KEY": "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",
  "AWS_INDEX_NAME": "GeoReference",
  "AWS_REGION": "us-east-1",
  "AZURE_APP_ID": "26465bb2-fe53-4972-901e-79a79246b565",
  "GOOGLE_ACCOUNT": {
    "CLIENT_ID": "",
    "URL_ACCOUNT": "",
    "iSSUER": ""
  },
  "GOOGLE_API": "AIzaSyAEIQELVtyPGxmJ8abFrwxGYXrFMXaY4bM",
  "LINKED_ACCOUNT": {
    "CLIENT_ID": "",
    "SECRET_ID": "",
    "URL_ACCOUNT": "",
    "iSSUER": ""
  },
  "LOGROCKET": "lean-staffing/applicant-portal",
  "MICROSOFT_ACCOUNT": {
    "AUTHORITY": "",
    "CLIENT_ID": "",
    "URL_ACCOUNT": ""
  },
  "PRODUCTION": true,
  "PSICOTHENICAL_TEST_16PF": "gOTsy8ck",
  "PSICOTHENICAL_TEST_MBTI": "DE8KiCvZ",
  "PSICOTHENICAL_TEST_VALANTI": "eHQNIY0S",
  "RECAPTCHA": {
    "SITE_KEY": "6LdwC04lAAAAADp7ncUmz7w5dnYXf0DaG6FWAHWF"
  },
  "TAG_MANAGER": "GTM-PH93MT9",
  "URL_DOMAIN": "leangroup.com",
  "URL_LOGIN": "https://portal.leangroup.com/"
}
