import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageModal } from '@services/message-modal/message-modal';

@Component({
  selector: 'app-error-message-modal',
  templateUrl: './error-message-modal.component.html',
  styleUrls: ['./error-message-modal.component.scss'],
})
export class ErrorMessageModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public messageData: MessageModal, public dialogRef: MatDialogRef<ErrorMessageModalComponent>) {}

  /**
   * Closes the modal
   */
  close() {
    this.dialogRef.close();
  }
}
