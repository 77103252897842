<!-- 
<df-messenger
*ngIf="chatBotVisible"
#Bot
class="bot_wp"
intent="WELCOME"
chat-title="Lean Solutions Group"
agent-id="a804a81b-8611-49af-a942-aed9f34c6e85"
language-code="en"
chat-icon="../assets/images/logos/lss_white_arrow_lg.svg"
></df-messenger> -->

<router-outlet></router-outlet>
