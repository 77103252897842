import { Injectable } from '@angular/core';
import { UserData } from '@app-main/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private userData: UserData;

  /**
   * Stores the userData locally
   * @param userData the user data to be saved
   */
  public setUserData(userData: UserData) {
    this.userData = userData;
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  /**
   * Returns the userData from local variable. If not there, it tries from localStorage.
   * If not found it returns null
   */
  public getUserData(): UserData | null {
    if (this.userData) {
      return this.userData;
    }
    const userData = localStorage.getItem('userData');
    if (userData) {
      return JSON.parse(userData) as UserData;
    }
    return null;
  }

  /**
   * Clears the userData from locally
   */
  public clearUserData(): void {
    localStorage.clear();
  }
}
