import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-snackbar-warning',
  templateUrl: './snackbar-warning.component.html',
  styleUrls: ['./snackbar-warning.component.scss'],
})
export class SnackbarWarningComponent {
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  text: string;
  textButton: string;
  close() {
    this.closeEvent.emit();
  }
}
